<template>
  <b-card>
    <div class="my-2">
      <div class="row justify-content-between">
        <div class="col-md-4 col-12" />
        <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0 col-md-4 col-12">
          <b-button
            variant="primary"
            data-action-type="new"
            @click="
              (v) => {
                this.$router.push({ name: 'damage-adjustment-new' });
              }
            "
          >
            {{ $t('new') }}
          </b-button>
          <vue-excel-xlsx
            :data="itemsArray"
            :columns="tableColumns"
            :filename="this.$route.name"
            :sheetname="'xlsxSheet'"
            class="btn btn-relief-success ml-1 p-0 ptn-sm"
          >
            <img
              src="@/assets/images/icons/xls.jpg"
              alt=""
              style="height: 39px;width: auto;"
            >
          </vue-excel-xlsx>
          <b-button
            variant="relief-danger"
            class="ml-1 p-0 ptn-sm"
            @click="printDocument"
          >
            <img
              src="@/assets/images/icons/pdf.jpg"
              alt=""
              style="height: 39px;width: auto;"
            >
          </b-button>
        </div>
      </div>
    </div>
    <g-table
      ref="adjustment-table"
      :items="invoiceProvider"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
      :deleteButton="{ visiable: false, handler: remove }"
    >
      <template
        v-if="hideActions === false"
        #actions="{ item }"
      >
        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${item.id}-prev-icon`"
            icon="EyeIcon"
            data-action-type="preview"
            class="mx-1 clickable "
            :hidden="true"
            @click="editItem(item)"
          />
          <b-tooltip
            :title="$t('preview')"
            placement="bottom"
            :target="`invoice-row-${item.id}-prev-icon`"
          />
          <feather-icon
            :id="`invoice-row-${item.id}-preview-icon`"
            data-action-type="edit"
            icon="EditIcon"
            class="mx-1 clickable"
            @click="editItem(item);"
          />
          <b-tooltip
            :title="$t('edit')"
            placement="bottom"
            :target="`invoice-row-${item.id}-preview-icon`"
          />
          <feather-icon
            :id="`invoice-row-${item.id}-preview-icon`"
            data-action-type="print"
            icon="PrinterIcon"
            class="mx-1 clickable"
            @click="print(item);"
          />
          <feather-icon
            :id="`invoice-row-${item.id}-delete-icon`"
            data-action-type="delete"
            icon="TrashIcon"
            stroke="red"
            class="danger"
            @click="remove(item);"
          />
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import GTable from '@/pages/Shared/Table.vue';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    GTable,
  },
   mixins: [reportMixin],
  data() {
    return {
      filter: {
        transactionType: '',
        branchId: null,
        id: null,
        dealerId: null,
        storeId: null,
        ItemId: null,
        FromDate: null,
        toDate: null,
        orderClause: null,
      },
      isTableBusy: false,
      items: [],
      totalRows: 0,
      routName: this.$route.name,
      itemsArray: [],
      hideActions: false
    };
  },
  computed: {
    tableColumns() {
      return [
        {
        key: 'code',
        field: 'code',
        label: this.$t('code'),
        sortable: true
        },
        {
          key: 'transactionDate',
          field: 'transactionDate',
          label: this.$t('transactionDate'),
          sortable: true,
          formatter: (value, key, item) => {
            return this.getDate(item.transactionDate);
          },
        },
        {
          key: 'transactionTime',
          field: 'transactionTime',
          label: this.$t('transactionTime'),
          sortable: true,
        },
        {
          key: this.isRight ? 'storeArabicName' : 'storeEnglishName',
          field: this.isRight ? 'storeArabicName' : 'storeEnglishName',
          label: this.$t('storeName'),
          sortable: true,
        },
        {
          key: 'notes',
          field: 'notes',
          label: this.$t('notes'),
          sortable: true,
        },
        {
          key: 'actions',
        },
      ];
    },
    name() {
      const currentRouteName = this.$route.name;
      if (currentRouteName === 'damage-adjustment') return 'damageAdjustment';
      return this.name
    },
  },
  watch: {
    name(newVal) {
      this.filter.transactionType = newVal;
      this.refreshItems();
    },
  },
  mounted() {
    this.filter.branchId = this.branchId;
  },
  methods: {
    hide() {
      this.hideActions = true
    },
    pdfExport(name) {
      html2canvas(document.getElementById('mainTable')).then(function(canvas){
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
          doc.save(`${name}.pdf`);
      });
    },
    printDocument() {
      this.hide()
        setTimeout(() => {this.pdfExport(this.$route.name)
        this.hideActions = false
        }, 500);
    },
    refreshItems() {
      this.$refs['adjustment-table'].refreshTable();
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({
            url: `ItemTransactions/${this.name}`,
            id: item.id,
          })
            .then(() => {
              this.doneAlert({ text: this.$t('deletedSuccessfully') });
              this.refreshItems();
            });
        }
      );
    },
    editItem(item) {
        this.$router.push({
          name: 'damage-adjustment-edit',
          params: { id: item.id },
        });
    },
    print(item) {
      const printedItem = {
        id: item.id,
        transactionType: 12
      }
       this.printReport('DamageAdjustment-ar', printedItem);
    },
    invoiceProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
      this.get({ url: `ItemTransactions/${this.name}${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount === 0 ? 1 : totalCount;
          callback(data);
          this.itemsArray = data;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
  },
};
</script>
<style></style>
